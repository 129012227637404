import PropTypes from 'prop-types'
import React from 'react'

const Header = props => (
  <header id="header" style={props.timeout ? { display: 'none' } : {}}>
    <div className="logo">
      <span className="icon fa-diamond"></span>
    </div>
    <div className="content">
      <div className="inner">
        <h1>Caffeze</h1>
        <p>
          An artist and writer collective dedicated to exploring the divine feminine
        </p>
        <p>
          Visit us on Instagram: <a href="https://www.instagram.com/caffeze/">@caffeze</a>
        </p>
      </div>
    </div>
  </header>
)

Header.propTypes = {
  onOpenArticle: PropTypes.func,
  timeout: PropTypes.bool,
}

export default Header
